import React, { Component } from 'react';
import './SearchResultsPage.css';

// loading animation
import ReactLoading from 'react-loading';

// local components
import StreamerCard from '../StreamerCard/StreamerCard.js';
import StreamerProfile from '../StreamerProfile/StreamerProfile.js';

// <SearchResultsPage/> --------------------------------------------------------

class SearchResultsPage extends Component {


  constructor() {
    super();

    this.state = {
      selectedStreamerID: null
    };
  }


  // onClick -------------------------------------------------------------------


  onClick_SelectStreamer = (streamer) => {
    this.getStreamer(streamer);
  }


  // gets a streamer from IODB and adds it to
  async getStreamer(streamer) {

    let streamerID = streamer.streamer_id;
    let sortOrder = streamer.result_id;
    let sortInfo = streamer.sort_info;

    // don't do anything if this streamer is already selected
    if (this.state.selectedStreamerID === streamerID) { return; }

    // select streamer
    if (streamerID in this.props.fullStreamerProfiles) {

      // we've already cached this streamer, so just select it
      this.setState({selectedStreamerID: streamerID});

    } else {
      // we haven't searched for this streamer's full profile before
      // -> make network request for full profile
      let streamerObject = await this.props.getStreamerFromIODB(streamerID, sortOrder, this.props.searchParameters, sortInfo);

      // add sortInfo to full streamer object
      streamerObject['sortInfo_Games'] = sortInfo.games;
      streamerObject['sortInfo_Genres'] = sortInfo.genres;
      streamerObject['sortInfo_Themes'] = sortInfo.themes;
      streamerObject['sortInfo_Keywords'] = sortInfo.keywords;
      streamerObject['sortInfo_RecommendedGames'] = sortInfo.recommended_games;
      streamerObject['videos'] = [];

      // add full streamerObject to our list of streamer objects
      let streamerCache = this.props.fullStreamerProfiles;
      streamerCache[streamerID] = streamerObject;
      this.props.update(streamerCache, this.props.fullStreamerProfilesTag);
      this.setState({selectedStreamerID: streamerID});

      // make request to get Streamer's videos
      // COMMENTED OUT FOR LAGGY REASONS
      // this.getStreamerVideos(streamerID);
    }
  }


  // gets a streamers videos and adds it to streamer profile
  async getStreamerVideos(streamerID) {
    let videos = await this.props.getStreamerVideos(streamerID);
    let streamerCache = this.props.fullStreamerProfiles;
    streamerCache[streamerID]['videos'] = videos;
    this.props.update(streamerCache, this.props.fullStreamerProfilesTag);
  }

  // Render --------------------------------------------------------------------


  // renders search results as <StreamerCard/>
  renderSearchResultCards = () => {
    let results = [];

    for (let i = 0; i < this.props.streamerCards.length; i++) {

      let streamer = this.props.streamerCards[i];

      let selectedBool = this.state.selectedStreamerID === streamer.streamer_id;

      results.push(
        <div
          key={i}
          onClick={() => this.onClick_SelectStreamer(streamer)}>
          <StreamerCard
            key={streamer.result_id}
            username={streamer.username}
            bio={streamer.bio}
            websiteURL={streamer.twitch_url}
            profilePicture={streamer.avatar_url}
            numVideos={streamer.num_videos}
            numGames={streamer.num_games}
            numIndie={streamer.num_indie}
            numCrowdfunded={streamer.num_crowdfunded}
            numFollowers={streamer.num_followers}
            favoriteGames={streamer.favoriteGames}
            favoriteGenres={streamer.favoriteGenres}
            selected={selectedBool}
          />
        </div>
      );
    }
    return results;
  }



  renderAboutSearchResults = () => {

    // if aboutResults is empty, then search is still happening (or broke)
    if (Object.keys(this.props.aboutResults).length === 0) {
      return (
        <div id="about_search_results_container" className="primary_highlight_2_border">
          <ReactLoading type="bars" width="20%" height="20%"/>
          <h1 className="primary_highlight_2_txt">Processing Search</h1>
        </div>
      );
    }


    let filtersData = [];
    if (this.props.aboutResults.filters) {
      for (let i = 0; i < this.props.aboutResults.filters.length; i++) {
        filtersData.push(
          <div className="filters_row">
            <h2 className="primary_highlight_3_txt">{this.props.aboutResults.filters[i]['filter']}</h2>
            <h2 className="primary_highlight_3_txt">{this.props.aboutResults.filters[i]['num_streamers']}</h2>
          </div>
        );
      }
    }


    return (
      <div id="about_search_results_container" className="primary_highlight_2_border">
        <h1 id="num_results_title" className="primary_highlight_2_txt">{this.props.aboutResults.num_results} Search Results</h1>
        <p id="click_description" className="primary_highlight_2_txt">Click on a Streamer Card to view their full profile</p>

        <h1 className="primary_highlight_2_txt">Applied Filters</h1>
        {filtersData}
      </div>
    );
  }

  renderSelectedStreamer = () => {

    // make sure streamer exists
    if (!this.state.selectedStreamerID) {return this.renderAboutSearchResults();}
    if (!this.state.selectedStreamerID in this.props.fullStreamerProfiles) {return this.renderAboutSearchResults();}

    let streamer = this.props.fullStreamerProfiles[this.state.selectedStreamerID];
    console.log(streamer);
    return (
      <div className="streamer_profile_container">
        <StreamerProfile
          key={streamer.result_id}
          username={streamer.username}
          bio={streamer.bio}
          websiteURL={streamer.twitch_url}
          profilePicture={streamer.avatar_url}
          numVideos={streamer.num_videos}
          numViews={streamer.num_views}
          numLivestreams={streamer.num_livestreams}
          numGames={streamer.num_games}
          numIndie={streamer.num_indie}
          numBeta={streamer.num_beta}
          numCrowdfunded={streamer.num_crowdfunded}
          numFollowers={streamer.num_followers}
          favoriteGames={streamer.favoriteGames}
          favoriteGenres={streamer.favoriteGenres}
          playedWeeksFromLaunch={streamer.played_weeks_from_launch}

          renderRelatedToSearchSection={true}
          sortInfo_Games={streamer.sortInfo_Games}
          sortInfo_Genres={streamer.sortInfo_Genres}
          sortInfo_Themes={streamer.sortInfo_Themes}
          sortInfo_Keywords={streamer.sortInfo_Keywords}
          sortInfo_RecommendedGames={streamer.sortInfo_RecommendedGames}

          gamesByKeyword={streamer.games_by_keyword}
          gamesByGenre={streamer.games_by_genre}
          gamesByTheme={streamer.games_by_theme}

          games={streamer.games}
          genres={streamer.genres}
          themes={streamer.themes}
          keywords={streamer.keywords}
          videos={streamer.videos}
          renderVideos={false}
        />
      </div>
    );
  }


  render() {
    return (
      <div className="SEARCHRESULTSPAGE primary_color_4_bg">

        <div className="results_left_container">
          {this.renderSelectedStreamer()}
        </div>

        <div className="results_right_container primary_color_5_bg">

          <div className="streamer_cards_container">
            <div id="search_again_row" className="primary_color_4_border">
              <button
                id="search_again_button"
                className="primary_highlight_2_txt primary_highlight_3_border primary_color_2_hover_border primary_color_2_hover_txt"
                onClick={this.props.returnToSearchParameters}>
                Modify Search
              </button>
            </div>
            {this.renderSearchResultCards()}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResultsPage;
