/*
  Helper Class for making network requests to IndieOutreach Server
*/


// URLS
let url = 'https://api.indieoutreach.io';
//let url = 'http://localhost:3000';
let search_url = `${url}/search`;

class IODB_API {


  getHeaders() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return myHeaders;
  }

  // Performs search!
  async search(searchParameters) {


    var requestOptions = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(searchParameters),
      redirect: 'follow'
    };

    let response = await fetch(`${search_url}/streamers`, requestOptions);
    let result = await response.json();
    return result;
  }



  // gets a full streamer profile from database
  async getStreamer(streamerID, sortOrder, searchParameters, sortInfo) {

    let url = search_url + '/streamer/id/';
    let response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        search_parameters: searchParameters,
        sort_order: sortOrder,
        sort_info: sortInfo,
        add_games_to_sort_info: 1,
        streamer_id: streamerID
      })
    })

    .then(function (a) {
      return a.json();
    });

    console.log(response);
    return response;
  }



  // get streamer videos
  async getStreamerVideos(streamerID) {

    console.log(streamerID);
    let url = search_url + "/videos";
    let response = await fetch(url,  {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        streamer_id: streamerID,
        get_all_games: true
      })
    })

    .then(function (a) {
      return a.json();
    });

    return response;
  }

}

export default IODB_API;
