import React, { Component } from 'react';
import './TagInputArea.css';


class SelectOneInput extends Component {

  constructor() {
    super();
  }


  // Render --------------------------------------------------------------------

  renderOptions = () => {
    let result = [];

    for (let i = 0; i < this.props.options.length; i++) {

      let option = this.props.options[i];

      if (option === this.props.selectedOption) {

        // option is selected
        result.push(
          <div className="option_tag_selected primary_color_3_bg" key={i}>
            <h3 className="text primary_highlight_1_txt">{option}</h3>
          </div>
        );

      } else {

        // option isn't selected
        result.push(
          <div
            className="option_tag primary_color_4_border primary_highlight_2_txt primary_highlight_1_hover_txt"
            onClick={() => this.props.selectOption(option)}
            key={i}>
            <h3 className="text">{option}</h3>
          </div>
        );
      }
    }

    return result;
  }


  // render <SelectOneInput/>
  render() {
    return (
      <div className="SELECTONE">

        <div className="tags_container primary_color_5_bg">
          {this.renderOptions()}
        </div>

      </div>
    );
  }
}

export default SelectOneInput;
