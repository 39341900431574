import React, { Component } from 'react';
import './LandingPage.css';

// images
import TwitchGlitch from '../../Images/Twitch_Logo/twitch_glitch_white.png';
import VideoIcon from '../../Images/LandingPage/video_play_button.png';
import GameIcon from '../../Images/LandingPage/games_icon.png';

// text
let description1 = "IndieOutreach is a PR search engine built for game developers";
let description2 = "Quickly find the Twitch streamers who are most likely to play your game ";
let description3 = "IndieOutreach is currently in a reduced state because AWS hosting can be expensive on large datasets";


let videosDescription = "All 7 million videos used by IndieOutreach are tagged with time-sensitive game specific information to ensure every search is both accurate and exhaustive. ";
let streamersDescription = "IndieOutreach searches through 150,000 active Twitch streamers and returns the ones most likely to play your game. ";
let gamesDescription = "IndieOutreach uses information like genres, themes, keywords, and release dates from over 40,000 games to make search results as accurate as possible.";
class LandingPage extends Component {


  constructor() {
    super();

    this.state = {
      activeDataPanel: ""
    };
  }

  // Mouse Interactions --------------------------------------------------------
  /*
    - For handling hover interactions with the About Data panels
    - if you hover over "150,000+ Streamers", it'll flip to show a description about the streamers
  */

  onMouseEnterDataPanel = (key) => {
    this.setState({activeDataPanel: key});
  }

  onMouseLeaveDataPanel = () => {
    this.setState({activeDataPanel: ""});
  }


  // Render --------------------------------------------------------------------

  renderAboutDataItem = (metric, title) => {

    let img, dataDescription;
    if (title === 'Videos') {
      img = VideoIcon;
      dataDescription = videosDescription;
    } else if (title === 'Streamers') {
      img = TwitchGlitch;
      dataDescription = streamersDescription;
    } else if (title === 'Games') {
      img = GameIcon;
      dataDescription = gamesDescription;
    }

    // if the current data panel is being hovered over, render info about it
    if (title === this.state.activeDataPanel) {
      return (
        <div
          className="about_data_item_container fade_in_background_color primary_color_5_hover_bg"
          onMouseLeave={this.onMouseLeaveDataPanel}
          >

            <p className="white_txt description data_panel_description">{dataDescription}</p>
        </div>
      );
    } else {

      // if current data panel isn't being hovered over, just show panel icon
      return (
        <div
          className="about_data_item_container primary_color_5_hover_bg"
          onMouseEnter={() => this.onMouseEnterDataPanel(title)}
          >

          <img src={img} className="about_data_icon"/>
          <h1 className="white_txt data_title">{metric}</h1>
          <h1 className="white_txt data_title">{title}</h1>
        </div>
      );
    }

  }


  render() {
    return (
      <div className="LANDINGPAGE">
        <h1 className="title white_txt">Find Streamers, <span className="bold">Faster</span></h1>
        <p className="description white_txt">{description1}</p>
        <p className="description white_txt">{description2}</p>
        <div className="boundary_line"></div>
        <p className="description white_txt">{description3}</p>
        <p className="description white_txt">Development has been halted since 2018, but thanks for stopping by</p>


        {/*
        <div className="boundary_line"></div>
        <div className="about_data_container">
          {this.renderAboutDataItem("7M+", "Videos")}
          {this.renderAboutDataItem("150,000+", "Streamers")}
          {this.renderAboutDataItem("40,000+", "Games")}
        </div>
        */}
        <button
          id="search_button"
          className="white_txt primary_color_3_hover_txt"
          onClick={this.props.onClick_GoToNextPage}>
          Search
        </button>
      </div>
    );
  }
}

export default LandingPage;
