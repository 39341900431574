import React, { Component } from 'react';
import './StreamerProfile.css';


import TWITCH_LOGO from '../../Images/Twitch_Logo/twitch_glitch_purple.png';

import TopTable from '../Display/TopTable/TopTable.js';
import DisplayGames from '../Display/DisplayGames/DisplayGames.js';

// import charts

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,

  PieChart,
  Pie,
  Sector,
  Cell,

  LineChart,
  Line,
  ReferenceLine
} from 'recharts';


// <StreamerProfile/> ----------------------------------------------------------

class StreamerProfile extends Component {


  constructor() {
    super();

    this.state = {
      selectedGenre: "",
      selectedTheme: "",
      selectedKeyword: "",
      currentStreamerName: ""
    }
  }

  // onCLick -------------------------------------------------------------------

  onClick_SelectSearchTag = (category, tag) => {
    if (category === "Genres" || category === "Genre") {
      this.setState({selectedGenre: tag, selectedTheme: "", selectedKeyword: ""});
    } else if (category === "Themes" || category === "Theme") {
      this.setState({selectedGenre: "", selectedTheme: tag, selectedKeyword: ""});
    } else if (category === "Keywords" || category === "Keyword") {
      this.setState({selectedGenre: "", selectedTheme: "", selectedKeyword: tag});
    }
  }

  // Formatting ----------------------------------------------------------------


  // takes a number and adds commas for thousand places
  // ex: (1000000 -> 1,000,000)
  formatNumber = (number) => {
    if (number) {
      return number.toLocaleString();
    }
    return number;
  }


  // Render: At a Glance -------------------------------------------------------

  // renders the At a Glance section
  // - indie / crowdfunded / AAA piechart
  // - barchart breakdown of when streamer plays games in relation to release date
  renderAtAGlance = () => {

    // extract numbers from props to make easier to reference
    let numIndie = this.props.numIndie;
    let numGames = this.props.numGames;
    let numCrowdfunded = this.props.numCrowdfunded;
    let percentCrowdfunded = Math.round(numCrowdfunded / numGames * 100);
    let percentIndie = Math.round(numIndie / numGames * 100);

    // Indie / Crowdfunded / AAA Pie Chart
    let gamesPie = [];
    gamesPie.push({name: 'Crowdfunded (' + percentCrowdfunded + '%)' , value: numCrowdfunded});
    gamesPie.push({name: 'Indie (' + percentIndie + '%)', value: numIndie - numCrowdfunded});
    gamesPie.push({name: 'Other', value: numGames - numIndie});
    let pieColors = ['#8E5DE8', '#4b367c', '#19171c'];

    let streamingHabits = this.renderDevPlayHabits(600);

    return (
      <div id="at_a_glance_container" className="primary_color_5_border">
        <div className="flex_row">
          {this.renderPieChart(gamesPie, pieColors)}
          <div className="vertical_boundry primary_highlight_3_bg"></div>
          <div className="">
            <h1 className="title primary_color_4_txt">Number of Videos by Game Release Dates</h1>
            {streamingHabits.chart}
            <h3 className="release_date_text primary_color_2_txt">{streamingHabits.percentPreLaunch}% <span className="primary_color_4_txt">of videos are pre game launch</span></h3>
            <h3 className="release_date_text primary_color_2_txt">{streamingHabits.percentLaunch}% <span className="primary_color_4_txt">are in the month directly after launch</span></h3>
            <h3 className="release_date_text primary_color_2_txt">{streamingHabits.percentPostLaunch}% <span className="primary_color_4_txt">of videos are at least one month post launch</span></h3>

          </div>
        </div>
      </div>
    );
  }


  // renders a pie chart
  renderPieChart = (pieData, pieColors) => {
    return (
      <PieChart
        width={320}
        height={340}>
        <Pie
          data={pieData}
          cx={160}
          cy={155}
          innerRadius={35}
          outerRadius={110}
          fill="#82ca9d"
          label>
          {
            pieData.map((entry, index) => <Cell fill={pieColors[index % pieColors.length]}/>)
          }
        </Pie>
        <Tooltip/>
        <Legend/>
      </PieChart>
    );
  }


  // takes streamer.played_weeks_from_launch and turns it into a bar chart
  // let's you see how often a streamer plays games in a certain range
  // - launch, prelaunch, early development, post launch, etc
  renderDevPlayHabits = (width) => {

    let weekRanges = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let weekTimes = this.props.playedWeeksFromLaunch;

    if (!weekTimes) {
      return (
        <h1>No play habits available</h1>
      );
    }

    // get number of times each range was played
    for (let i = 0; i < weekTimes.length; i++) {
      if (weekTimes[i] == 666666) {
        // skip this, its an invalid datapoint
      } else if (weekTimes[i] < -52) {
        weekRanges[0] += 1;               // more than a year before
      } else if (weekTimes[i] < -12) {
        weekRanges[1] += 1;               // between 3 months and a year
      } else if (weekTimes[i] < -4) {
        weekRanges[2] += 1;               // between 1 month and 3 months
      } else if (weekTimes[i] < -1) {
        weekRanges[3] += 1;               // 2, 3, or 4 weeks
      } else if (weekTimes[i] < 0) {
        weekRanges[4] += 1;               // 1 week before
      } else if (weekTimes[i] < 1) {
        weekRanges[5] += 1;               // 1 week after
      } else if (weekTimes[i] < 2) {
        weekRanges[6] += 1;               // 2 weeks after
      } else if (weekTimes[i] < 4) {
        weekRanges[7] += 1;               // 1 month after
      } else if (weekTimes[i] < 12) {
        weekRanges[8] += 1;               // 3 months after
      } else if (weekTimes[i] < 52) {
        weekRanges[9] += 1;               // 1 year after
      } else {
        weekRanges[10] += 1;              // more than 1 year after
      }
    }


    // convert weekRanges into a BarChartable format
    let barChartData = [];
    barChartData.push({name: "-1 Y+", videos: weekRanges[0]});
    barChartData.push({name: "-1 Y", videos: weekRanges[1]});
    barChartData.push({name: "-3 M", videos: weekRanges[2]});
    barChartData.push({name: "-1 M", videos: weekRanges[3]});
    barChartData.push({name: "-1 W", videos: weekRanges[4]});
    barChartData.push({name: "1 W", videos: weekRanges[5]});
    barChartData.push({name: "2 W", videos: weekRanges[6]});
    barChartData.push({name: "1 M", videos: weekRanges[7]});
    barChartData.push({name: "3 M", videos: weekRanges[8]});
    barChartData.push({name: "1 Y", videos: weekRanges[9]});
    barChartData.push({name: "1 Y+", videos: weekRanges[10]});

    // create graph to render as variable
    let barChart = (
      <div>
        <BarChart
          width={width} height={280}
          data={barChartData}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Bar dataKey="videos" fill="#1C1C1C" />
        </BarChart>
      </div>
    );


    // get percentages of intervals
    let numPreLaunch = weekRanges[0] + weekRanges[1] + weekRanges[2];
    numPreLaunch += weekRanges[3] + weekRanges[4];
    let numFirstMonth = weekRanges[5] + weekRanges[6] + weekRanges[7];
    let numRest = weekRanges[8] + weekRanges[9] + weekRanges[10];
    let numTotal = numPreLaunch + numFirstMonth + numRest;

    let returnObject = {
      percentPreLaunch: Math.round(numPreLaunch / numTotal * 100),
      percentLaunch: Math.round(numFirstMonth / numTotal * 100),
      percentPostLaunch: Math.round(numRest / numTotal * 100),
      chart: barChart
    };

    return returnObject;
  }




  // Render: Top Bar -----------------------------------------------------------

  renderTopBar = () => {
    return (
      <div className="top_container primary_highlight_2_bg">
        <div className="top_left_space_column">
          <div className="top_left_top_color primary_color_5_bg"></div>
        </div>
        <div className="profile_picture_column">
          <div className="above_profile_picture_space primary_color_5_bg"></div>
          <img id="profile_picture" src={this.props.profilePicture}/>
        </div>
        <div className="about_streamer_column">

          {/* Streamer username, bio, and link to twitch */}
          <div className="about_streamer_top_row primary_color_5_bg">
            <div className="username_row">
              <h1 id="username" className="primary_color_2_txt text">{this.props.username}</h1>
              <a href={this.props.websiteURL} target="_blank"><img id="twitch_logo" src={TWITCH_LOGO}/></a>
            </div>

            <p id="bio" className="primary_highlight_3_txt text">{this.props.bio}</p>
          </div>

          {/* contains stats about streamer - #followers, #views, etc */}
          <div className="about_streamer_bottom_row">
            <div className="streamer_stats_row">
              {this.renderStat(this.props.numFollowers, "followers")}
              {this.renderStat(this.props.numViews, "views")}
              {this.renderStat(this.props.numVideos, "videos")}
              {this.renderStat(this.props.numLivestreams, "livestreams")}
            </div>

            <div className="streamer_stats_row">
              {this.renderStat(this.props.numGames, "games")}
              {this.renderStat(this.props.numIndie, "indies")}
              {this.renderStat(this.props.numCrowdfunded, "crowdfunded")}
              {this.renderStat(this.props.numBeta, "pre-launch")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // renders a stat like numFollowers, numViews, etc
  renderStat = (stat, tag) => {
    return (
      <h3 className="primary_color_3_txt stat_text">{this.formatNumber(stat)} <span className="primary_color_4_txt">{tag}</span></h3>
    );
  }


  // Render: Related to Search -------------------------------------------------

  renderRelatedToSearch = () => {

    if (!this.props.renderRelatedToSearchSection) {return;}

    return (
      <div className="section_container primary_highlight_2_5_bg">
        <h1 className="section_title primary_color_5_txt">Related to Search</h1>
        {this.renderGames(this.props.sortInfo_Games, "Games")}
        {this.renderSearchParametersNoImg(this.props.gamesByGenre, "Genres")}
        {this.renderGamesRelatedToSearchTags(this.props.gamesByGenre, "Genre", this.state.selectedGenre)}
        {this.renderSearchParametersNoImg(this.props.gamesByTheme, "Themes")}
        {this.renderGamesRelatedToSearchTags(this.props.gamesByTheme, "Theme", this.state.selectedTheme)}
        {this.renderSearchParametersNoImg(this.props.gamesByKeyword, "Keywords")}
        {this.renderGamesRelatedToSearchTags(this.props.gamesByKeyword, "Keyword", this.state.selectedKeyword)}
        {this.renderGames(this.props.sortInfo_RecommendedGames, "Similar Games")}
        <div className="spacing_row_40px"></div>
      </div>
    );
  }


  // render game name, cover, and # times streamer played it
  renderGames = (games, title) => {
    let results = [];

    // build list of games to render
    for (let i = 0; i < games.length; i++) {
      let game = games[i];
      if (game['times_played'] > 0) {
        results.push(
          <div className="game_container primary_color_5_bg" key={i}>

            {/*
              - For linking to IGDB
            <a href={game.igdb_url}>
              <img id="game_img" src={game.logo_url}/>
            </a>
            */}
            <img id="game_img" src={game.logo_url.url}/>

            <div className="game_row primary_color_5_bg">
              <h3 className="game_text primary_highlight_2_txt">Played <span className="primary_color_1_txt">{game.times_played}</span> Times</h3>
            </div>
          </div>
        );
      }
    }

    if (results.length > 0) {
      return (
        <div>
          <h1 className="secondary_title primary_color_4_txt">{title}</h1>
          <div className="flex_row_with_wrap">
            {results}
          </div>
        </div>
      );
    }
  }


    // for rendering sortInfo_Themes, sortInfo_Keywords, and sortInfo_Genres
    // - these have name of parameter and times played
    // - also render the search parameters the streamer is missing
    renderSearchParametersNoImg = (parameters, title) => {

      let results = [];

      let selectedItem = "";
      if (title === "Genres") { selectedItem = this.state.selectedGenre; }
      if (title === "Themes") { selectedItem = this.state.selectedTheme; }
      if (title === "Keywords") { selectedItem = this.state.selectedKeyword; }


      console.log(parameters);
      
      let testInfo = [];
      for (let genreName in parameters) {

        let timesGenrePlayed = parameters[genreName].length;
        if (timesGenrePlayed > 0) {
          if (genreName === selectedItem) {
            results.push(
              <div className="parameter_container_border primary_color_2_bg primary_color_3_hover_bg">
                <div
                  className="parameter_container primary_color_5_bg"
                  key={genreName}
                  onClick={() => this.onClick_SelectSearchTag(title, "")}>
                  <h3 className="parameter_text primary_highlight_1_txt">{genreName}</h3>
                  <div className="parameter_spacing"></div>
                  <h3 className="parameter_text primary_color_1_txt">{timesGenrePlayed}</h3>
                </div>
              </div>
            )
          } else {
            results.push(
              <div className="parameter_container_border primary_highlight_2_5_bg primary_color_2_hover_bg">
                <div
                  className="parameter_container primary_color_5_bg"
                  key={genreName}
                  onClick={() => this.onClick_SelectSearchTag(title, genreName)}>
                  <h3 className="parameter_text primary_highlight_1_txt">{genreName}</h3>
                  <div className="parameter_spacing"></div>
                  <h3 className="parameter_text primary_color_1_txt">{timesGenrePlayed}</h3>
                </div>
              </div>
            );
          }
        }
      }

      if (results.length > 0) {
        return (
          <div>
            <h1 className="secondary_title primary_color_4_txt">{title}</h1>
            <div className="parameters_row">
              {results}
            </div>
          </div>
        );
      }
    }





  // renders <DisplayGames/>
  // category = "genre", "keyword", or "theme"
  // keyword = what tag these games belong to (ie: rogue-lite, action, etc)
  renderGamesRelatedToSearchTags = (games, category, keyword) => {

    if (keyword === "") { return; }

    return(
      <div className="primary_color_5_bg">
        <DisplayGames
          gamesToRender={games}
          keywordCategory={category}
          keyword={keyword}
          closeDisplayGames={() => this.onClick_SelectSearchTag(category, "")}
        />
      </div>
    );
  }


  // Render Most Played --------------------------------------------------------

  // renders a table of most played
  renderMostPlayed = () => {


    //alert(this.props.genres);
    let genres = this.convertDictionaryToList(JSON.parse(this.props.genres));
    let themes = this.convertDictionaryToList(JSON.parse(this.props.themes));
    let keywords = this.convertDictionaryToList(JSON.parse(this.props.keywords));
    let games = this.convertDictionaryToList(JSON.parse(this.props.games));

    let items = [genres, themes, keywords, games];
    let titles = ["genres", "themes", "keywords", "games"];

    return (
      <div className="section_container primary_color_5_bg">
        <h1 className="section_title primary_highlight_1_txt">Most Played</h1>
        <TopTable
          items={items}
          titles={titles}
          minRows={5}
          maxRows={15}
          valueKey="times_played"
        />
      </div>
    );
  }


  // given a dictionary of format:
  //  - {indie: 25, action: 32}
  // convert it into a list so <TopTable/> can render it, of form:
  // - [{name: indie, times_played: 25}, {name: action, times_played: 32}]
  convertDictionaryToList = (dictionary) => {
    let results = [];
    for (let key in dictionary) {
      results.push({name: key, times_played: dictionary[key]});
    }
    return results;
  }

  // Render Videos -------------------------------------------------------------
  /*
    - NOT BUILT
    getting videos takes too long with current MySQL, so it just won't be added
  */

  renderVideos = () => {
    if (! this.props.renderVideos) {return;}

    return (
      <p>{JSON.stringify(this.props.games)}</p>
    );
  }


  // Render --------------------------------------------------------------------

  render() {

    // if user has clicked on a new streamer, we want to clear our state
    if (this.props.username !== this.state.currentStreamerName) {
      this.setState({
        selectedGenre: "",
        selectedTheme: "",
        selectedKeyword: "",
        currentStreamerName: this.props.username
      });
    }

    return (
      <div className="STREAMERPROFILE primary_highlight_1_bg">

        {this.renderTopBar()}
        {this.renderAtAGlance()}
        {this.renderRelatedToSearch()}
        {this.renderMostPlayed()}
        {this.renderVideos()}
      </div>
    );
  }
}

export default StreamerProfile;
