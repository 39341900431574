/*
  <AutoCompleteInput/> is a component that allows users to type in a string
    and get autocompleted answers, then select one.
*/

import React, { Component } from 'react';
import './TagInputArea.css';

// components
import Tag from './Tag.js';

class AutoCompleteInput extends Component {

  constructor() {
    super();

    this.state = {
      inputText: ""
    };
  }


  // onClick -------------------------------------------------------------------

  // calls this.props.selectTag() to select a tag
  // clears input text
  onClick_SelectTag = (tagName) => {
    this.props.selectTag(tagName);
    this.setState({inputText: ""});
  }

  // Text Input ----------------------------------------------------------------

  // updates input text when someone types in input field
  updateInputText = (event) => {
    if (this.state.inputText !== event.target.value) {
      this.setState({inputText: event.target.value})
    }
  }

  // returns a list of options that pass autocomplete filter
  getAvailableOptions = () => {
    let result = [];
    let options = this.props.options;
    let phrase = this.state.inputText.toLowerCase();

    // get list of results that match user input
    for (let i = 0; i < options.length; i++) {
      if (options[i].toLowerCase().indexOf(phrase) > -1) {
        result.push(options[i]);
      }
    }

    return result;
  }

  // given a list, returns all options that aren't already in props.selectedOptions
  filterOutSelectedOptions = (options) => {
    let result = [];

    // build map of selected options for quicker lookup later
    let selectedLookup = {};
    for (let i = 0; i < this.props.selectedTags.length; i++) {
      let name = this.props.selectedTags[i]['name'];
      selectedLookup[name] = true;
    }

    //
    for (let i = 0; i < options.length; i++) {
      if (! (options[i] in selectedLookup)) {
        result.push(options[i]);
      }
    }
    return result;
  }

  // Render --------------------------------------------------------------------


  // Return a list of Tags to render
  renderSelectedTags = () => {
    let tags = [];

    for (let i = 0; i < this.props.selectedTags.length; i++) {
      let name = this.props.selectedTags[i]['name'];
      let value = this.props.selectedTags[i]['active'];
      tags.push(
        <div className="tag_spacing_selected_options" key={i}>
          <Tag
            name={name}
            value={value}
            starActive={this.props.starActive}
            xActive={true}
            borderActive={false}
            deselectTag={() => this.props.deselectTag(name)}
            flipTagValue={() => this.props.flipTagValue(name)}
          />
        </div>
      );
    }
    return tags;
  }


  // renders all options a user can select
  // - filters out options that have been already selected
  // - filters out options that don't match search parameters
  renderOptions = () => {

    // only render options if there is input
    if (this.state.inputText === "") { return []; }

    let tags = [];
    let options = this.getAvailableOptions();
    options = this.filterOutSelectedOptions(options);
    options = options.splice(0, 20);

    for (let i = 0; i < options.length; i++) {
      let name = options[i];

      tags.push(
        <div className="tag_spacing_container">
          <div onClick={() => this.onClick_SelectTag(name)}>
            <Tag
              name={name}
              starActive={false}
              xActive={false}
              borderActive={false}
              key={i}
            />
          </div>
        </div>
      );
    }

    return tags;
  }


  renderAutoCompleteOptions = () => {

    let options = this.renderOptions();

    // make sure there are results
    if (options.length === 0) { return; }

    return (
      <div className="primary_color_4_5_bg">
        <div className="boundary_line primary_color_4_bg"></div>
        <div className="tags_content_container">
          {options}
        </div>
      </div>
    );
  }


  // render full <AutoCompleteInput/>
  render() {

    let inputActive = "primary_color_5_border primary_highlight_1_bg";
    let inputInactive = "primary_color_5_border primary_highlight_2_bg primary_highlight_1_hover_bg";
    let inputCSS = (this.state.inputText === "") ? inputInactive : inputActive;

    return (
      <div className="AUTOCOMPLETE ">
        <div className="top_row_container_select_active">

          {/* Selected Tags + Input */}
          <div className="top_row_container">

            <input
              id="new_tag_input"
              className={inputCSS}
              value={this.state.inputText}
              placeholder={this.props.inputPrompt}
              onChange={this.updateInputText.bind(this)}
              autoFocus
            />

            <div className="tags_container primary_color_4_5_bg">
              {this.renderSelectedTags()}
            </div>
          </div>
        </div>

        {this.renderAutoCompleteOptions()}
      </div>
    );
  }
}

export default AutoCompleteInput;
