/*
  TopTable is a component that renders a table of most-played items
*/

import React, { Component } from 'react';
import './TopTable.css';

// <TopTable/> -----------------------------------------------------------------

class TopTable extends Component {

  constructor() {
    super();

    this.state = {
      expanded: false
    };
  }


  // onClick -------------------------------------------------------------------

  onClick_Expand = () => {
    this.setState({expanded: !this.state.expanded});
  }

  // Number of Rows ------------------------------------------------------------

  getNumRowsToRender = () => {

    let numRows;

    // find the list with the most items
    // (we don't want to render empty rows, so if numRows > maxlist.length, use maxList.length)
    let maxNumItems = 0;
    for (let i = 0; i < this.props.items.length; i++) {
      if (this.props.items[i].length > maxNumItems) {
        maxNumItems = this.props.items[i].length;
      }
    }

    //
    let numRowsFromProps = (this.state.expanded) ? this.props.maxRows : this.props.minRows;
    numRows = (numRowsFromProps > maxNumItems) ? maxNumItems : numRowsFromProps;

    //alert(numRows);
    return numRows;
  }


  // Render --------------------------------------------------------------------

  // renders the titles of columns
  renderTitleRow = () => {

    let titles = [];
    for (let i = 0; i < this.props.titles.length; i++) {
      titles.push(
        <div key={i} className="item_container">
          <h1 className="title primary_color_2_txt">{this.props.titles[i]}</h1>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="left_spacing_container"></div>
        {titles}
      </div>
    );
  }


  // renders items
  renderItemRows = () => {


    let valueKey = this.props.valueKey;

    // 1) sort lists of items
    let sortedLists = [];
    for (let i = 0; i < this.props.items.length; i++) {
      let items = this.props.items[i];
      items.sort(function(a, b) {
        return b[valueKey] - a[valueKey];
      });
      sortedLists.push(items);
    }


    // 2) build list of items to render
    let numRows = this.getNumRowsToRender();

    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push([]);
    }

    // i = which list you're on
    // j = which row you're on && which item in list
    for (let i = 0; i < sortedLists.length; i++) {
      for (let j = 0; j < numRows; j++) {
        if (sortedLists[i].length <= j) {
          rows[j].push(<div key={j} className="item_container"></div>);
        } else {
          rows[j].push(
            <div className="item_container" key={j}>
              <h3 className="title primary_highlight_3_txt item_name">{sortedLists[i][j]['name']}</h3>
              <h3 className="title primary_color_2_txt">{sortedLists[i][j][valueKey]}</h3>
            </div>
          );
        }
      }
    }


    // 3) build up a list of rows
    let result = [];
    for (let i = 0; i < rows.length; i++) {
      result.push(
        <div className="row">
          <div className="left_spacing_container">
            <h2 className="title primary_color_2_txt">{i + 1}</h2>
          </div>
          {rows[i]}
        </div>
      );
    }

    // return the row
    return result;
  }


  renderExpandButton = () => {

    let viewMoreText = (this.props.maxRows - this.getNumRowsToRender()) + " more..."
    let buttonText = (this.state.expanded) ? "hide" : viewMoreText;
    return (
      <div className="row">
        <div className="left_spacing_container"></div>
        <div className="item_container">
          <button
            id="expand_button"
            className="primary_color_3_bg primary_highlight_3_txt primary_highlight_1_hover_txt"
            onClick={this.onClick_Expand}>
            {buttonText}
          </button>
        </div>
      </div>
    );
  }


  // render <TopTable/>
  render() {
    return (
      <div className="TOPTABLE primary_color_5_bg">
        {this.renderTitleRow()}
        {this.renderItemRows()}
        {this.renderExpandButton()}
      </div>
    );
  }
}

export default TopTable;
