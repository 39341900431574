import React, { Component } from 'react';
import './Navbar.css';


// Import Logos
import IO_Logo_White from '../../Images/IO_Logo/logo_white.svg';
import Twitter_Logo_White from '../../Images/Twitter_Logo/Twitter_Logo_White.png';

class Navbar extends Component {
  render() {
    return (
      <div className="NAVBAR primary_color_3_bg">
        <img id="io_logo" src={IO_Logo_White} alt="IndieOutreach" onClick={this.props.onClick_ReturnHome}/>

        <div className="links-container">
          <h2 className="link white_txt primary_color_2_hover_txt">Twitch</h2>

          <a href="https://twitter.com/thedarrendawson" id="twitter_link_container">
            <img id="twitter_logo" src={Twitter_Logo_White} alt="Twitter"/>
          </a>

        </div>
      </div>
    );
  }
}

export default Navbar;
