import React, { Component } from 'react';
import './TagInputArea.css';

import AutoCompleteInput from './AutoCompleteInput.js';
import MultipleChoiceInput from './MultipleChoiceInput.js';
import SelectOneInput from './SelectOneInput.js';



class TagInputArea extends Component {

  constructor() {
    super();
  }


  // UPDATE <AutoCompleteInput/> + <MultipleChoiceInput/> ----------------------
  /*
    Functions for updating values
     - select an option: given a tag, update this.props.selectedOptions
     - deselect an option
  */


  // returns true if a tag with name=tagName exists in selectedOptions
  checkIfSelected = (tagName) => {
    let selected = this.props.selectedOptions;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i]['name'] === tagName) { return true; }
    }
    return false;
  }


  // call this function to select a tag
  selectTag = (tagName, value) => {

    // check to make sure tag isn't already selected
    if (this.checkIfSelected(tagName)) {return;}

    // create new selectedTags object and add tag to that
    let newSelected = this.props.selectedOptions;
    newSelected.push({name: tagName, active: value});

    // save the modified selectedTags object to state using ustra
    this.props.update(newSelected, this.props.selectedOptionsTag);
  }


  // call this function to deselect a tag
  deselectTag = (tagName) => {

    let newSelected = [];
    let changeMade = false;
    let selected = this.props.selectedOptions;

    // loop through selected tags and add back all selected options that aren't the one we are deselecting
    for (let i = 0; i < selected.length; i++) {
      if (tagName !== selected[i]['name']) {
        newSelected.push(selected[i]);
      } else {
        changeMade = true;
      }
    }

    // if we've made a change, update it
    if (changeMade) {
      this.props.update(newSelected, this.props.selectedOptionsTag);
    }
  }


  // call function to flip a selected tag's value
  // -> if tag isn't in props.selectedOptions, function doesn't do anything
  // {name: 'tag1', active: false} -> {name: 'tag1', active: true}
  flipTagValue = (tagName) => {

    let newSelected = [];
    let changeMade = false;
    let selected = this.props.selectedOptions;

    // loop through selected tags. Flip active boolean for desired tag
    for (let i = 0; i < selected.length; i++) {
      if (tagName !== selected[i]['name']) {
        newSelected.push(selected[i]);
      } else {
        let newTagValue = (! selected[i]['active']);
        newSelected.push({name: tagName, active: newTagValue});
        changeMade = true;
      }
    }

    // if we've made a change, update!
    if (changeMade) {
      this.props.update(newSelected, this.props.selectedOptionsTag);
    }
  }



  // UPDATE <SelectOneInput/> --------------------------------------------------
  /*
    Functions for updating <SelectOneInput/> (stage in development)
  */


  selectSingleOption = (newOption) => {

    // Only run this command if in a valid mode
    if (typeof this.props.selectedOptions === "string" && this.props.inputMode === "SelectOne") {
      if (newOption !== this.props.selectedOptions) {
        this.props.update(newOption, this.props.selectedOptionsTag);
      }
    }
  }


  // Get Component -------------------------------------------------------------
  /*
    <TagInputArea/> has multiple options of input
    These functions return one
     - <AutoCompleteInput/>
     - <MultipleChoiceInput/>
     - <SelectOneInput/>
  */


  getAutoCompleteComponent = () => {
    return (
      <AutoCompleteInput
        selectedTags={this.props.selectedOptions}
        options={this.props.options}
        starActive={this.props.starActive}
        selectTag={this.selectTag}
        deselectTag={this.deselectTag}
        flipTagValue={this.flipTagValue}
        inputPrompt={this.props.inputPrompt}
      />
    );
  }

  getMultipleChoiceComponent = () => {
    return (
      <MultipleChoiceInput
        selectedTags={this.props.selectedOptions}
        options={this.props.options}
        tagConversions={this.props.tagConversions}
        starActive={this.props.starActive}
        selectTag={this.selectTag}
        deselectTag={this.deselectTag}
        flipTagValue={this.flipTagValue}
        checkIfTagSelected={this.checkIfSelected}
        inputPrompt={this.props.inputPrompt}
      />
    );
  }

  getSelectOneComponent = () => {
    return (
      <SelectOneInput
        options={this.props.options}
        selectedOption={this.props.selectedOptions}
        selectOption={this.selectSingleOption}
      />
    );
  }


  // Render --------------------------------------------------------------------

  render() {

    // get type of input to render (<AutoCompleteInput/>, <MultipleChoiceInput/>, <SelectOneInput/>)
    // get containerCSS (selectOne doesn't have a border :hover effect)
    let input;
    let containerCSS;
    if (this.props.inputMode === 'AutoComplete') {
      input = this.getAutoCompleteComponent();
      containerCSS = "input_method_container primary_color_4_border";
    } else if (this.props.inputMode === 'MultipleChoice') {
      input = this.getMultipleChoiceComponent();
      containerCSS = "input_method_container primary_color_4_border";
    } else if (this.props.inputMode === 'SelectOne') {
      input = this.getSelectOneComponent();
      containerCSS = "input_method_container primary_color_4_border";
    } else {
      return <div></div>;
    }


    return (
      <div className="TAGINPUTAREA">
        <h1 className="title white_txt">{this.props.title}</h1>
        <div className={containerCSS}>
          {input}
        </div>
      </div>
    );
  }
}

export default TagInputArea;
