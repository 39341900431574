import React, { Component } from 'react';
import './DisplayGames.css';


class DisplayGames extends Component {


  constructor() {
    super();

    this.state = {
      filterInputText: "",
      maxGamesToRender: 20
    }
  }


  // onClick -------------------------------------------------------------------

  onClick_ExpandMaxGamesLimit = () => {
    this.setState({maxGamesToRender: this.state.maxGamesToRender + 20});
  }

  // Filter Input Text ---------------------------------------------------------

  // updates input text when someone types in input field
  updateFilterInputText = (event) => {
    this.setState({filterInputText: event.target.value, maxGamesToRender: 20});
  }


  // Render --------------------------------------------------------------------


  renderGames = () => {

    let filterText = this.state.filterInputText;
    let games = this.props.gamesToRender[this.props.keyword];
    let results = [];

    // sort list of games by times played, descending order
    games.sort(function(a, b) {
      return b['times_played'] - a['times_played'];
    });


    // create list of games to render
    // - filter out games that don't match user filters
    for (let i = 0; i < games.length; i++) {
      if (games[i]['name'].toLowerCase().indexOf(filterText.toLowerCase()) >= 0) {
        results.push(
          <div className="game_container primary_color_5_bg" key={i}>
            <img id="game_img" src={games[i]['logo_url']} title={games[i]['name']}/>

            <div className="game_row primary_color_5_bg">
              <h3 className="game_text primary_highlight_2_txt">Played <span className="primary_color_1_txt">{games[i]['times_played']}</span> Times</h3>
            </div>
          </div>
        );
      }
    }



    // if there aren't any results that pass filter, notify user
    if (results.length === 0) {
      return (
        <div>
          <p className="primary_highlight_2_txt">No Results Found</p>
        </div>
      );
    }

    // get slice of results to render
    // && results.length < this.state.maxGamesToRender
    let gamesToRender = results.slice(0, this.state.maxGamesToRender);
    let expandButton;
    if (results.length > gamesToRender.length) {
      expandButton = <button
        id="expand_button"
        className="primary_color_3_bg primary_highlight_3_txt primary_highlight_1_hover_txt"
        onClick={this.onClick_ExpandMaxGamesLimit}
        >More
      </button>;
    }
    return (
      <div>
        <div id="games_container">
          {gamesToRender}
        </div>

        {expandButton}
      </div>

    );
  }




  render() {

    if (! this.props.keyword in this.props.gamesToRender) { return null; }

    let games = this.props.gamesToRender[this.props.keyword];
    if (!games) { return null; }
    let title = <h1 id="title" className="primary_highlight_1_txt">
      {games.length} Games with {this.props.keywordCategory}: <span className="primary_color_1_txt">{this.props.keyword}</span>
    </h1>

    if (games.length === 0) { return null; }

    return (
      <div id="DISPLAYGAMES" className="primary_color_5_bg">
        <div className="row">
          {title}
          <button
            id="close_button"
            className="primary_color_5_bg primary_highlight_2_txt primary_color_2_hover_txt"
            onClick={this.props.closeDisplayGames}>X
          </button>
        </div>

        <div className="filter_bar_container primary_color_4_bg">
          <input
            id="filter_input"
            className="primary_color_4_bg primary_highlight_2_txt primary_color_4_focus_border"
            placeholder="filter games..."
            value={this.state.filterInputText}
            onChange={this.updateFilterInputText.bind(this)}
          />

          <p id="search_icon" className="primary_highlight_2_txt">&#x26B2;</p>
        </div>



        {this.renderGames()}
      </div>
    );
  }
}

export default DisplayGames;
