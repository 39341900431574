import React, { Component } from 'react';
import './SearchParametersPage.css';


//Components
import TagInputArea from '../Input/TagInputArea/TagInputArea.js';
import OptionalInput from '../Input/OptionalInput/OptionalInput.js';

// Tag Conversions -------------------------------------------------------------
/*
  - When showing tags with long names, convert to abbreviated versions
      ex: "Role-playing (RPG)" -> "RPG"
*/

let GENRE_CONVERSIONS = {
  "Real Time Strategy (RTS)": "RTS",
  "Turn-based strategy (TBS)": "TBS",
  "Role-playing (RPG)": "RPG",
  "Hack and slash/Beat 'em up": "Hack and slash",
  "Platform": "Platformer"
}

let THEME_CONVERSIONS = {
  "4X (explore, expand, exploit, and exterminate)": "4X"
}


let GAME_TIME_OPTIONS = [
  "alpha",
  "beta",
  "prelaunch",
  "launch",
  "released"
];


// <SearchParametersPage/> -----------------------------------------------------

class SearchParametersPage extends Component {


  // onClick -------------------------------------------------------------------

  onClick_Search = () => {
    // perform network search request
    this.props.search();

    // move on to search results page
    this.props.onClick_GoToNextPage();
  }


  // render --------------------------------------------------------------------

  render() {
    return (
      <div className="SEARCHPARAMETERSPAGE">

          {/* Search Button */}
          <div className="column_row">
            <div className="search_row_container">
              <button
                id="search_button"
                className="white_txt primary_color_3_hover_txt"
                onClick={this.onClick_Search}>
                Search!
              </button>
            </div>
            <div className="boundary_line"></div>

          </div>

          <div id="search_parameters_container">

            {/* Search Parameters */}
            <div className="scroll_container_inner">
              <div className="column_row">

                <div className="parameters_container">

                  {/* About Game Parameters */}
                  <h1 className="white_txt category_title">Describe your Game</h1>

                  <TagInputArea
                    inputMode="SelectOne"
                    options={GAME_TIME_OPTIONS}
                    selectedOptions={this.props.developmentStage}
                    selectedOptionsTag={this.props.developmentStageTag}
                    update={this.props.update}
                  />
                  <TagInputArea
                    title="Genres"
                    inputMode="MultipleChoice"
                    selectedOptions={this.props.genres}
                    selectedOptionsTag={this.props.genresTag}
                    options={this.props.genreOptions.sort()}
                    tagConversions={GENRE_CONVERSIONS}
                    starActive={true}
                    inputPrompt="Add Genres"
                    update={this.props.update}
                  />
                  <TagInputArea
                    title="Themes"
                    inputMode="MultipleChoice"
                    selectedOptions={this.props.themes}
                    selectedOptionsTag={this.props.themesTag}
                    options={this.props.themeOptions.sort()}
                    tagConversions={THEME_CONVERSIONS}
                    starActive={true}
                    inputPrompt="Add Themes"
                    update={this.props.update}
                  />
                  <TagInputArea
                    title="Keywords"
                    inputMode="AutoComplete"
                    selectedOptions={this.props.keywords}
                    selectedOptionsTag={this.props.keywordsTag}
                    options={this.props.keywordOptions}
                    starActive={true}
                    inputPrompt="Enter keyword..."
                    update={this.props.update}
                  />
                  <TagInputArea
                    title="Similar Games"
                    inputMode="AutoComplete"
                    selectedOptions={this.props.similarGames}
                    selectedOptionsTag={this.props.similarGamesTags}
                    options={this.props.gameOptions}
                    starActive={true}
                    inputPrompt="Enter game..."
                    update={this.props.update}
                  />


                  {/* Explanation of how filters work */}
                  <div id="filters_explanation_row">
                    <div id="filters_explanation_container">
                      <div className="tag_container transparent_to_opaque_primary_color_3_bg">
                        <h3 id="star" className="primary_highlight_1_txt">&#9733;</h3>
                        <h3 id="x" className="primary_color_5_txt">X</h3>
                      </div>
                      <h2 className="primary_highlight_3_txt">Search will filter out all streamers who haven't played this tag</h2>
                    </div>
                  </div>

                  <div id="filters_explanation_row">
                    <div id="filters_explanation_container">
                      <div className="tag_container transparent_to_opaque_primary_color_3_bg">
                        <h3 id="star" className="primary_color_5_txt">&#9733;</h3>
                        <h3 id="x" className="primary_color_5_txt">X</h3>
                      </div>
                      <h2 className="primary_highlight_3_txt">Search will prioritize streamers who've played this tag, but won't filter by it</h2>
                    </div>
                  </div>

                  {/* About Streamers Parameters */}
                  <h1 className="white_txt category_title">Describe your Ideal Streamers</h1>

                  <h1 className="white_txt sub_category_title">Filters</h1>
                  <div className="filters_container">
                    <div className="filters_column">
                      <OptionalInput
                        title="Min # Followers"
                        active={this.props.minFollowers['active']}
                        value={this.props.minFollowers['value']}
                        updateTag={this.props.minFollowersTag}
                        update={this.props.update}
                      />

                      <OptionalInput
                        title="Min # Games"
                        active={this.props.minGames['active']}
                        value={this.props.minGames['value']}
                        updateTag={this.props.minGamesTag}
                        update={this.props.update}
                      />


                      <OptionalInput
                        title="Min # Indie"
                        active={this.props.minIndie['active']}
                        value={this.props.minIndie['value']}
                        updateTag={this.props.minIndieTag}
                        update={this.props.update}
                      />

                      <OptionalInput
                        title="Min # Crowdfunded"
                        active={this.props.minCrowdfunded['active']}
                        value={this.props.minCrowdfunded['value']}
                        updateTag={this.props.minCrowdfundedTag}
                        update={this.props.update}
                      />

                    </div>

                    <div className="filters_column">

                      <OptionalInput
                        title="Max # Followers"
                        active={this.props.maxFollowers['active']}
                        value={this.props.maxFollowers['value']}
                        updateTag={this.props.maxFollowersTag}
                        update={this.props.update}
                      />


                      <OptionalInput
                        title="Min # Beta"
                        active={this.props.minBeta['active']}
                        value={this.props.minBeta['value']}
                        updateTag={this.props.minBetaTag}
                        update={this.props.update}
                      />

                      <OptionalInput
                        title="Min % Indie"
                        active={this.props.minPercentIndie['active']}
                        value={this.props.minPercentIndie['value']}
                        updateTag={this.props.minPercentIndieTag}
                        update={this.props.update}
                      />
                      
                      <OptionalInput
                        title="Min % Crowdfunded"
                        active={this.props.minPercentCrowdfunded['active']}
                        value={this.props.minPercentCrowdfunded['value']}
                        updateTag={this.props.minPercentCrowdfundedTag}
                        update={this.props.update}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default SearchParametersPage;
