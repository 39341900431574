import React, { Component } from 'react';
import './App.css';
import './Colors.css';

// Components
import Navbar from './Components/Navbar/Navbar.js';
import LandingPage from './Components/LandingPage/LandingPage.js';
import SearchParametersPage from './Components/SearchParametersPage/SearchParametersPage.js';
import SearchResultsPage from './Components/SearchResultsPage/SearchResultsPage.js';

//

// IGDB imports
import GAME_NAMES       from './Data/igdb/game_names.json';
import COMPANIES_LOOKUP from './Data/igdb/companies.json';
import GENRES_LOOKUP    from './Data/igdb/genres.json';
import KEYWORDS_LOOKUP  from './Data/igdb/keywords.json';
import PLATFORMS_LOOKUP from './Data/igdb/platforms.json';
import THEMES_LOOKUP    from './Data/igdb/themes.json';


// Helper Functions
import Ustra from './Helper/Ustra.js';         // For storing/maintaining State data across app
import IODB_API from './Helper/IODBHelper.js'; // For Network Requests to IODB
var IODB = new IODB_API();


// State Data Declaration ------------------------------------------------------
/*
  App uses USTRA for maintaining data across components
  Create a skeleton for what State data should look like
   - Make sure each item in state has a unique identifier key

  Pass skeleton to Ustra to build up paths so we can update state easily later
*/


// Path Tags

// Search Parameters --- |
var PT_searchParameters = "searchParameters";
var PT_limit = "limit";
var PT_offset = "offset";

var PT_aboutGame = "aboutGames";                 // About Game
var PT_genres = "genres";
var PT_keywords = "keywords";
var PT_themes = "themes";
var PT_similarGames = "similarGames";
var PT_developmentStage = "stageInDevelopment";
var PT_aboutStreamers = "aboutStreamers";        // About Streamer
var PT_similarStreamers = "similarStreamers";
var PT_minFollowers = "minFollowers";
var PT_maxFollowers = "maxFollowers";
var PT_minGames = "minGames";
var PT_minIndie = "minIndie";
var PT_minCrowdfunded = "minCrowdfunded";
var PT_minBeta = "minBeta";
var PT_minPercentIndie = "minPercentIndie";
var PT_minPercentCrowdfunded = "minPercentCrowdfunded";

// About Server --- |
var PT_aboutServer = "aboutServer";
var PT_autoCompleteOptions = "autoCompleteOptions";
var PT_genreOptions = "genreOptions";
var PT_themeOptions = "themeOptions";
var PT_keywordOptions = "keywordOptions";
var PT_gameOptions = "gameOptions";


// Search Results --- |
var PT_searchResults = "searchResults";
var PT_searchResultCards = "searchResultsCards";
var PT_fullStreamerProfiles = "fullStreamerProfiles";
var PT_aboutResults = "aboutSearchResults";
var PT_resultsRecommendedGames = "resultsRecommendedGames";
var PT_resultsSearchParameters = "resultsSearchParameters";


// App State --- |
var PT_currentPage = "currentPage";





// Data Skeleton
let dataSkeleton = {

  // App State |
  // -> For maintaining continuity in the App
  // -> 'searchParameters', 'landingPage', 'searchResults', AND 'DEV_TEST'
  [PT_currentPage]: "landingPage",


  // About Server |
  // -> Used for Search Parameters (autocomplete uses genreOptions etc)
  // -> these tags are dynamically assigned from the IndieOutreach server on bootup
  [PT_aboutServer]: {
    [PT_autoCompleteOptions]: {
      [PT_genreOptions]: [],
      [PT_themeOptions]: [],
      [PT_keywordOptions]: [],
      [PT_gameOptions]: []
    }
  },

  // Search Parameters |
  // ->  User inputs data, this object gets sent to server for search
  [PT_searchParameters]: {
    [PT_aboutGame]: {
      [PT_genres]: [],
      [PT_themes]: [],
      [PT_keywords]: [{name: 'indie', active: true}, {name: 'rogue-lite', active: false}],
      [PT_similarGames]: [],
      [PT_developmentStage]: "prelaunch"
    },

    [PT_aboutStreamers]: {
      [PT_minFollowers]: {active: true, value: 500},
      [PT_maxFollowers]: {active: false, value: 100000},
      [PT_minGames]: {active: false, value: 15},
      [PT_minIndie]: {active: false, value: 5},
      [PT_minCrowdfunded]: {active: false, value: 1},
      [PT_minBeta]: {active: false, value: 5},
      [PT_minPercentIndie]: {active: true, value: 15},
      [PT_minPercentCrowdfunded]: {active: false, value: 10}
    },

    [PT_offset]: 0,
    [PT_limit]: 100
  },


  // Search Results |
  [PT_searchResults]: {
    [PT_searchResultCards]: [],
    [PT_aboutResults]: {},
    [PT_resultsRecommendedGames]: [],
    [PT_resultsSearchParameters]: {}
  },

  // Streamer Profiles Cache
  [PT_fullStreamerProfiles]: {}
}


// create Ustra
var USTRA = new Ustra(dataSkeleton);

// IGDB Info -------------------------------------------------------------------


function loadIGDBInfoIntoList(lookup) {
  let list = [];
  for (let key in lookup) {
    list.push(lookup[key]);
  }
  return list;
}

const _companies = loadIGDBInfoIntoList(COMPANIES_LOOKUP);
const _genres    = loadIGDBInfoIntoList(GENRES_LOOKUP);
const _keywords  = loadIGDBInfoIntoList(KEYWORDS_LOOKUP);
const _platforms = loadIGDBInfoIntoList(PLATFORMS_LOOKUP);
const _themes    = loadIGDBInfoIntoList(THEMES_LOOKUP);



// App -------------------------------------------------------------------------

class App extends Component {

  constructor() {
    super();

    this.state = {
      truth: USTRA.get_truth()
    }

    this.getDataAboutServer();

    if (window.innerWidth < 1000) {
      alert("Please use the Desktop Version of IndieOutreach");
    }
  }


  // Ustra Update --------------------------------------------------------------
  /*
    Functions for updating information
      -> pass updates to ustra, use results to update app.state
  */

  update = (value, path_tag) => {
    let new_state = USTRA.update(value, path_tag);
    this.setState({
      truth: new_state
    });
  }

  // Network Requests ----------------------------------------------------------


  // gets data from server about search parameters for autocomplete purposes
  // - genres, themes, keywords, game names
  async getDataAboutServer() {
    let parameterTags = {
      [PT_genreOptions]   : _genres,
      [PT_themeOptions]   : _themes,
      [PT_keywordOptions] : _keywords,
      [PT_gameOptions]    : GAME_NAMES
    };

    this.update(parameterTags, PT_autoCompleteOptions);
  }


  // performs a search for streamers
  async performSearch(searchParameters) {
    let searchResults = await IODB.search(searchParameters);
    let result = {
      [PT_searchResultCards]: searchResults.searchResultsCards,
      [PT_resultsRecommendedGames]: searchResults.recommendedGames,
      [PT_resultsSearchParameters]: searchResults.searchParameters,
      [PT_aboutResults]: searchResults.aboutSearchResults
    };
    this.update(result, PT_searchResults);
  }



  // Next Page -----------------------------------------------------------------

  //
  goToNextPage = () => {
    let currentPage = this.state.truth[PT_currentPage];
    if (currentPage === "landingPage") {
      this.update("searchParameters", PT_currentPage);
    } else if (currentPage === "searchParameters") {
      this.update("searchResults", PT_currentPage);
    }
  }


  goToSearchParametersPage = () => {
    let searchResults = {
      [PT_searchResultCards]: [],
      [PT_aboutResults]: {},
      [PT_resultsRecommendedGames]: [],
      [PT_resultsSearchParameters]: {}
    };

    this.update(searchResults, PT_searchResults);
    this.update({}, PT_fullStreamerProfiles);
    this.update("searchParameters", PT_currentPage);
  }

  // will reset website to as it is on load
  // - clears search results
  // - clears fullStreamerProfiles
  // - sets app to be on landing page
  // EXCEPT: keeps the data about server
  resetApp = () => {

    let searchResults = {
      [PT_searchResultCards]: [],
      [PT_aboutResults]: {},
      [PT_resultsRecommendedGames]: [],
      [PT_resultsSearchParameters]: {}
    };

    this.update(searchResults, PT_searchResults);
    this.update({}, PT_fullStreamerProfiles);
    this.update("landingPage", PT_currentPage);
  }

  // Render --------------------------------------------------------------------


  // renders the current active page
  renderPage = () => {

    let truth = this.state.truth;
    let currentPage = truth[PT_currentPage];

    if (currentPage === "landingPage") {
      return (
        <LandingPage
          update={this.update}
          onClick_GoToNextPage={this.goToNextPage}
        />
      );

    } else if (currentPage === "searchParameters") {

      return (
        <SearchParametersPage
          search={() => this.performSearch(truth[PT_searchParameters])}
          update={this.update}
          onClick_GoToNextPage={this.goToNextPage}

          searchParameters={truth[PT_searchParameters]}
          genreOptions={truth[PT_aboutServer][PT_autoCompleteOptions][PT_genreOptions]}
          themeOptions={truth[PT_aboutServer][PT_autoCompleteOptions][PT_themeOptions]}
          keywordOptions={truth[PT_aboutServer][PT_autoCompleteOptions][PT_keywordOptions]}
          gameOptions={truth[PT_aboutServer][PT_autoCompleteOptions][PT_gameOptions]}

          genres={truth[PT_searchParameters][PT_aboutGame][PT_genres]}
          themes={truth[PT_searchParameters][PT_aboutGame][PT_themes]}
          keywords={truth[PT_searchParameters][PT_aboutGame][PT_keywords]}
          similarGames={truth[PT_searchParameters][PT_aboutGame][PT_similarGames]}
          developmentStage={truth[PT_searchParameters][PT_aboutGame][PT_developmentStage]}

          minFollowers={truth[PT_searchParameters][PT_aboutStreamers][PT_minFollowers]}
          maxFollowers={truth[PT_searchParameters][PT_aboutStreamers][PT_maxFollowers]}
          minGames={truth[PT_searchParameters][PT_aboutStreamers][PT_minGames]}
          minIndie={truth[PT_searchParameters][PT_aboutStreamers][PT_minIndie]}
          minCrowdfunded={truth[PT_searchParameters][PT_aboutStreamers][PT_minCrowdfunded]}
          minBeta={truth[PT_searchParameters][PT_aboutStreamers][PT_minBeta]}
          minPercentIndie={truth[PT_searchParameters][PT_aboutStreamers][PT_minPercentIndie]}
          minPercentCrowdfunded={truth[PT_searchParameters][PT_aboutStreamers][PT_minPercentCrowdfunded]}

          currentPageTag={PT_currentPage}
          genresTag={PT_genres}
          themesTag={PT_themes}
          keywordsTag={PT_keywords}
          similarGamesTags={PT_similarGames}
          developmentStageTag={PT_developmentStage}

          minFollowersTag={PT_minFollowers}
          maxFollowersTag={PT_maxFollowers}
          minGamesTag={PT_minGames}
          minIndieTag={PT_minIndie}
          minCrowdfundedTag={PT_minCrowdfunded}
          minBetaTag={PT_minBeta}
          minPercentIndieTag={PT_minPercentIndie}
          minPercentCrowdfundedTag={PT_minPercentCrowdfunded}
        />
      );

    } else if (currentPage === "searchResults"){

      return (
        <SearchResultsPage
          update={this.update}
          fullStreamerProfilesTag={PT_fullStreamerProfiles}
          getStreamerFromIODB={IODB.getStreamer}
          getStreamerVideos={IODB.getStreamerVideos}

          streamerCards={truth[PT_searchResults][PT_searchResultCards]}
          fullStreamerProfiles={truth[PT_fullStreamerProfiles]}
          searchParameters={truth[PT_searchParameters]}

          aboutResults={truth[PT_searchResults][PT_aboutResults]}
          returnToSearchParameters={this.goToSearchParametersPage}
        />
      );
    } else if (currentPage === "DEV_TEST") {

      return (
        <div className="TEST_CONTAINER">

        </div>
      );
    }
  }



  testOnClick = async () => {
    // <button onClick={this.testOnClick}>Test API</button>

    this.performSearch(this.state.truth[PT_searchParameters])
  }


  // render <App/>
  render() {

    return (
      <div className="App TWITCH_COLORS">
        <div className="App_Fullpage_Container primary_color_4_bg">
          <div className="App_Navbar_Container">
            <Navbar onClick_ReturnHome={this.resetApp}/>
          </div>

          <div className="App_Body_Container">
            {this.renderPage()}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
