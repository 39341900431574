import React, { Component } from 'react';
import './TagInputArea.css';

import Tag from './Tag.js';


class MultipleChoiceInput extends Component {

  constructor() {
    super();
    this.state = {
      selectActive: false
    };
  }


  // onClick -------------------------------------------------------------------

  onClick_ToggleActive = () => {
    let newActive = ! this.state.selectActive;
    this.setState({selectActive: newActive});
  }


  // Render --------------------------------------------------------------------


  // function returns the name that will be rendered on a <Tag/>
  // if there is a conversion that should be made, it returns converted name
  // - "Role-playing (RPG)" will return "RPG"
  // if there isn't a conversion, it just returns the tagName
  getNameToRender = (tagName) => {
    if (tagName in this.props.tagConversions) {
      return this.props.tagConversions[tagName];
    }
    return tagName;
  }


  // Return a list of Tags to render
  renderSelectedTags = () => {
    let tags = [];

    for (let i = 0; i < this.props.selectedTags.length; i++) {
      let name = this.props.selectedTags[i]['name'];
      let value = this.props.selectedTags[i]['active'];
      tags.push(
        <div className="tag_spacing_selected_options">
          <Tag
            name={this.getNameToRender(name)}
            value={value}
            starActive={this.props.starActive}
            xActive={true}
            borderActive={false}
            deselectTag={() => this.props.deselectTag(name)}
            flipTagValue={() => this.props.flipTagValue(name)}
            key={i}
          />
        </div>

      );
    }
    return tags;
  }


  // renders all of the options user can select.
  // - if option is selected already: render selected with onClick = deselect
  // - if option not selected: render unselected with onClick = select option
  renderOptions = () => {

    // make sure options exist before trying to render them
    if (!this.props.options) { return; }

    let tags = [];

    for (let i = 0; i < this.props.options.length; i++) {

      let name = this.props.options[i];

      // add tag
      if (this.props.checkIfTagSelected(name)) {

        // tag is selected -> render a selected tag with a deselect click
        tags.push(
          <div className="tag_spacing_container">
            <div onClick={() => this.props.deselectTag(name)}>
              <Tag
                name={this.getNameToRender(name)}
                starActive={false}
                xActive={false}
                borderActive={true}
                key={i}
              />
            </div>
          </div>
        );
      } else {

        // tag isn't selected -> render a non-selected tag with select on click
        tags.push(
          <div className="tag_spacing_container">
            <div onClick={() => this.props.selectTag(name, false)}>
              <Tag
                name={this.getNameToRender(name)}
                starActive={false}
                xActive={false}
                borderActive={false}
                key={i}
              />
            </div>
          </div>
        );
      }

    }
    return tags;
  }


  renderChoices = () => {
    if (this.state.selectActive) {
      return (
        <div>
          <div className="boundary_line primary_color_4_bg"></div>
          <div className="tags_content_container primary_color_4_5_bg">
            {this.renderOptions()}
          </div>
        </div>
      );
    }
  }

  // Render <MultipleChoiceInput/>
  render() {

    // get button html
    let buttonText = this.props.inputPrompt;
    let buttonCSS = "primary_color_5_border primary_highlight_2_bg primary_highlight_1_hover_bg";
    if (this.state.selectActive) {
      buttonText = "Done";
      buttonCSS = "primary_color_5_border primary_highlight_1_bg";
    }

    return (
      <div className="MULTIPLECHOICE">
        <div className="top_row_container_select_active">

          <div className="top_row_container">

            <button
              id="add_options_button"
              className={buttonCSS}
              onClick={this.onClick_ToggleActive}>
              {buttonText}
            </button>

            <div className="tags_container primary_color_4_5_bg">
              {this.renderSelectedTags()}
            </div>

          </div>
        </div>

        {this.renderChoices()}
      </div>
    );

  }
}

export default MultipleChoiceInput;
