/*
  <OptionalInput/> is a component that let's users input a value or turn off param
    - <input/> for getting user value
    - <button/> checkmark for disabling parameter

    Modifies an object of format
      - {active: bool, value: int (or str)}

*/

import React, { Component } from 'react';
import './OptionalInput.css';


class OptionalInput extends Component {

  constructor() {
    super();
  }


  // given a string "9999", returns "9,999"
  addCommasToNum = (numString) => {

    let result = "";
    let resultToBuild = [];
    let digits = numString.toString().split("");

    // keep track of times_run so you know when to add a comma
    for (let i = digits.length - 1, times_run = 0;   i >= 0;   i--, times_run = (times_run + 1) % 3) {
      resultToBuild.push(digits[i]);
      if (times_run === 2 && i !== 0) {
        resultToBuild.push(',');
      }
    }

    // convert back into string by reversing it
    for (let i = resultToBuild.length - 1; i >= 0; i--) {
      result += resultToBuild[i];
    }

    return result;
  }

  // takes a string "9,999" and removes the commas -> "9999"
  stripCommas = (numString) => {
    return numString.replace(',', '');
  }

  // onClick -------------------------------------------------------------------

  // function called to update toggle props.active in state
  onClick_ToggleActive = () => {
    let newActive = !this.props.active;
    let newValue = {active: newActive, value: this.props.value};
    this.props.update(newValue, this.props.updateTag);
  }


  // Update Text ---------------------------------------------------------------

  // updates input text when someone types in input field
  updateInputText = (event) => {

    let inputValue = Number(this.stripCommas(event.target.value));

    if (this.props.value !== inputValue) {
      let newValue = {active: this.props.active, value: inputValue};
      this.props.update(newValue, this.props.updateTag);
    }
  }

  // Render --------------------------------------------------------------------


  renderParameterTitle = () => {

    let activeCSS =  "title_container rounded_corners primary_color_3_bg primary_highlight_1_border";
    let inactiveCSS = "title_container primary_color_5_bg primary_color_5_border";
    let containerCSS = (this.props.active) ? activeCSS : inactiveCSS;
    let textColorCSS = (this.props.active) ? "primary_highlight_1_txt" : "primary_highlight_2_txt";
    return (
      <div className={containerCSS}>
        <h3 id="title" className={textColorCSS}>{this.props.title}</h3>
      </div>
    );
  }


  renderInput = () => {

    let activeCSS = "primary_highlight_1_bg primary_color_5_border";
    let inactiveCSS = "primary_highlight_3_bg primary_color_5_border";
    let inputCSS = (this.props.active) ? activeCSS : inactiveCSS;
    let valueToRender = this.addCommasToNum(this.props.value);

    return (
      <input
        id="input"
        className={inputCSS}
        value={valueToRender}
        onChange={this.updateInputText.bind(this)}
      />
    );
  }

  // renders <Tag/> component
  render() {

    let button;
    if (this.props.active) {
      button = <button id="checkmark_button" className="primary_color_2_bg primary_highlight_1_txt" onClick={this.onClick_ToggleActive}>&#10003;</button>
    } else {
      button = <button id="checkmark_button" className="primary_color_5_bg primary_color_3_hover_bg primary_color_5_border" onClick={this.onClick_ToggleActive}></button>
    }


    return (
      <div className="OPTIONALINPUT">
        <div className="left_container">
          {button}
          {this.renderParameterTitle()}
        </div>


        {this.renderInput()}
      </div>
    );
  }
}

export default OptionalInput;
