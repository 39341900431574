/*
  <StreamerCard/> is a search result card that shows off Streamer info
  - can be clicked to request full profile of streamer
*/

import React, { Component } from 'react';
import './StreamerCard.css';


class StreamerCard extends Component {

  constructor() {
    super();
  }

  // Render --------------------------------------------------------------------


  renderFavorites = () => {


    if (!this.props.favoriteGames) {return;}

    let favoriteGames = [];
    for (let i = 0; i < this.props.favoriteGames.length; i++) {
      favoriteGames.push(
        <p key={i} className="favorites_text primary_highlight_3_txt">{this.props.favoriteGames[i]}</p>
      );
    }

    return (
      <div className="favorites_container primary_color_5_border">
        {favoriteGames}
      </div>
    );
  }

  // renders <StreamerCard/> component
  render() {

    let borderCSS = (this.props.selected) ? "STREAMERCARD primary_color_2_bg" : "STREAMERCARD primary_color_5_bg primary_color_3_hover_bg";
    return (
      <div className={borderCSS}>
        <div id="card_container" className="primary_color_4_bg">
          <div className="left_container">
            <img id="profile_picture" className="primary_color_5_border" src={this.props.profilePicture}/>
            <h3 className="text primary_color_2_txt">{this.props.numFollowers} <span className="primary_highlight_3_txt">Followers</span></h3>
            <h3 className="text primary_color_2_txt">{this.props.numVideos} <span className="primary_highlight_3_txt">Videos</span></h3>
          </div>

          <div className="right_container">
            <a id="username" className="primary_color_2_txt primary_highlight_1_hover_txt" href={this.props.websiteURL} target="_blank">{this.props.username}</a>
            <p id="bio" className="primary_highlight_3_txt">{this.props.bio}</p>
            <div className="num_games_container">
              <h3 className="text num_games primary_color_2_txt">{this.props.numGames} <span className="primary_highlight_3_txt">Games |   </span></h3>
              <h3 className="text num_games primary_color_2_txt">{this.props.numIndie} <span className="primary_highlight_3_txt">Indie, </span></h3>
              <h3 className="text num_games primary_color_2_txt">{this.props.numCrowdfunded} <span className="primary_highlight_3_txt">Crowdfunded</span></h3>
            </div>

            <h3 className="text primary_color_2_txt">Most Played</h3>
            {this.renderFavorites()}
          </div>
        </div>
      </div>
    );
  }
}

export default StreamerCard;
