/*
  <Tag/> is an interactive label used in <TagInputArea/>
  This component is used for showing options in Search Parameters
*/

import React, { Component } from 'react';
import './TagInputArea.css';


class Tag extends Component {

  constructor() {
    super();
  }

  // Render --------------------------------------------------------------------


  renderXButton = () => {
    if (this.props.xActive) {
      return (
        <h3
          id="close_button"
          className="primary_color_5_txt primary_highlight_3_hover_txt"
          onClick={this.props.deselectTag}>
          X
        </h3>
      );
    }
  }

  renderStar = () => {
    if (this.props.starActive) {
      let starCSS = this.props.value ? "primary_highlight_1_txt primary_highlight_3_hover_txt" : "primary_color_5_txt primary_highlight_3_hover_txt";
      return (
        <h3
          id="star"
          className={starCSS}
          onClick={this.props.flipTagValue}>
            &#9733;
          </h3>
      );
    }
  }


  // renders <Tag/> component
  render() {

    // get tag background / hover styling
    let tagCSS;
    if (this.props.borderActive) {
      tagCSS = "TAG with_border primary_color_2_bg primary_highlight_1_border";
    } else if (this.props.xActive || this.props.starActive) {
      tagCSS = "TAG no_border primary_color_3_bg";
    } else {
      tagCSS = "TAG no_border primary_color_3_bg primary_color_2_hover_bg";
    }


    return (
      <div className={tagCSS}>
        <h3 className="text primary_highlight_1_txt">{this.props.name}</h3>

        <div className="star_close_container">
          {this.renderStar()}
          {this.renderXButton()}
        </div>

      </div>
    );
  }
}

export default Tag;
